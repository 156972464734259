<template>
  <CsTableCard
    card-header="Elenco Ispezioni"
    :rows="rows"
    :columns="columns"
    :loading="loading"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'count'">
        <!--        <span v-if="!hasToCountSchede()">100%</span>-->
        <!--        <span v-else>{{ countSchedeCompilate(props.row) }}/{{ countSchede(props.row) }}</span>-->
        {{ countSchedeCompilate(props.row) }}/{{ countSchede(props.row) }}
      </span>
      <cs-table-action-column-default
        v-else
        :props="props"
        :show-actions="
          canRemoveRow(props.row) || canDetailsRow(props.row) || canInvalidaRow(props.row) || canApprovaRow(props.row)
        "
      >
        <cs-table-action-item-details
          :show="canDetailsRow(props.row)"
          :row="props.row"
          @click="detailsRow"
        />
        <cs-table-action-item
          :show="canApprovaRow(props.row)"
          icon="CheckIcon"
          value="Approva"
          :row="props.row"
          @click="approvaRow"
        />
        <cs-table-action-item
          :show="canInvalidaRow(props.row)"
          icon="XIcon"
          value="Invalida"
          :row="props.row"
          @click="invalidaRow"
        />
        <cs-table-action-item-delete
          :show="canRemoveRow(props.row)"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>
import STATI from '@/models/statoSchedaEnum'

export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Opera',
          field: 'opera.nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Codice',
          field: 'opera.codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Stato',
          field: 'stato',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Data Ispezione',
          field: 'dataIspezione',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'Schede Compilate',
          field: 'count',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  created() {
    const { operaId } = this.$route.params
    if (this.isNotNullOrUndefined(operaId)) {
      this.$remote.opere.ispezioni_all(operaId)
        .then(result => {
          this.setBreadcrumbData(this.$store, this.$route.name, { operaId, nome: result.data.opera.nome })
          this.rows = result.data.ispezioni.map(m => ({
            id: m.id,
            opera: { nome: m.opera.nome, codice: m.opera.codice },
            stato: m.stato,
            dataIspezione: m.dataIspezione,
            nSchedeCompilate: m.schede.filter(f => f.statoId !== STATI.IN_COMPILAZIONE.id).length,
            nSchede: m.schede.length,
          }))
          this.loading = false
        })
        .catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loading = false })
    } else {
      this.$remote.ispezioni.all()
        .then(result => {
          this.rows = result.data
            .map(m => ({
              id: m.id,
              opera: { nome: m.opera.nome, codice: m.opera.codice },
              stato: m.stato,
              dataIspezione: m.dataIspezione,
              nSchedeCompilate: m.schede.filter(f => f.statoId !== STATI.IN_COMPILAZIONE.id).length,
              nSchede: m.schede.length,
            }))
        }).catch(err => { this.showReposonseMessage(err) })
        .finally(() => { this.loading = false })
    }
  },
  methods: {
    canDetailsRow(row) {
      return this.$grants.ISPEZIONI_VISUALIZZA_DETTAGLIO.can && (row.isApprovata || !this.$isConcessionario)
    },
    canRemoveRow(row) {
      return this.$grants.ISPEZIONI_NON_APPROVATE_ELIMINA.can && !row.isApprovata
        && (this.$isReferente || (!row.isValidata && row.isResponsabile))
    },
    canInvalidaRow(row) {
      return this.$grants.ISPEZIONI_VALIDATE_INVALIDA.can && row.isValidata && !row.isApprovata
    },
    canApprovaRow(row) {
      return this.$grants.ISPEZIONI_VALIDATE_APPROVA.can && row.isValidata && !row.isApprovata
    },
    // hasToCountSchede() {
    //   return true
    //   // const { id } = this.$route.params
    //   // return id == null || id === STATI.IN_COMPILAZIONE.id.toString()
    // },
    countSchedeCompilate(row) {
      return row.nSchedeCompilate
    },
    countSchede(row) {
      return row.nSchede
    },
    detailsRow(params) {
      this.$routing.ISPEZIONI_DETTAGLIO.push({ id: params.id })
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.ispezioni.delete(params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    invalidaRow(params) {
      this.showAttentionSwal().then(warn => {
        if (!warn.value) return

        this.loading = true
        this.$remote.ispezioni.invalida(params.id)
          .then(res => {
            this.deleteRow(this.rows, params.originalIndex)
            if (this.$route.params.id === STATI.COMPILATA.id || this.$route.params.id === null) {
              this.addRowAt(this.rows, res.data, params.originalIndex)
            }
            this.showSuccessMessage('L\'ispezione è stata invalidata con successo.')
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    approvaRow(params) {
      this.showAttentionSwal().then(warn => {
        if (!warn.value) return

        this.loading = true
        this.$remote.ispezioni.approva(params.id)
          .then(res => {
            this.deleteRow(this.rows, params.originalIndex)
            if (this.$route.params.id === STATI.APPROVATA.id || this.$route.params.id === null) {
              this.addRowAt(this.rows, res.data, params.originalIndex)
            }
            this.showSuccessMessage('L\'ispezione è stata approvata con successo.')
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
