const VALUES = Object.freeze({
  IN_COMPILAZIONE: {
    id: 1, label: 'IN COMPILAZIONE', codice: 1,
  },
  COMPILATA: {
    id: 2, label: 'COMPILATA', codice: 2,
  },
  VALIDATA: {
    id: 3, label: 'VALIDATA', codice: 3,
  },
  APPROVATA: {
    id: 4, label: 'APPROVATA', codice: 4,
  },

})

export default VALUES
